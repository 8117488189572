define('Brady_Checkout/js/mixin/pobox-mixin',[
    'Magento_Ui/js/lib/validation/validator',
    'jquery',
    'jquery/ui',
    'jquery/validate',
    'mage/translate',
], function (validator, $) {
    "use strict";

    return function () {
        validator.addRule(
            'validate-street-part', function (v) {
                var pattern = /((((p[\s\.]?)\s?[o\s][\.]?)\s?)|(post\s?office\s?)|(postal\s?)|(post\s?))((box|bin|b\.?)?\s?((num|number|no|#)\.?)?\s?\d+)/igm.test(v);

                if (!pattern) {
                    return true;
                } else {
                    return false;
                }
            },
            $.mage.__("Please note that we cannot ship to PO Boxes. Please enter a physical address.")
        );
    };
});
